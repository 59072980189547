import React, {Component} from 'react';
import Select from 'react-select';
import Slider from 'rc-slider';
import {connect} from 'react-redux';
import _ from 'lodash';
import {browserHistory} from 'react-router';
import BaseTable from '../../components/common/BaseTable';
import {clearFormValues, setFormValues, setEditingMode} from '../../actions/form_actions';
import AddressAutoComplete from '../containers/AddressAutoComplete';
import swal2 from 'sweetalert2';
import axios from 'axios';
import {selectCustomStyle} from '../../utils/SelectCustomStyle';

import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(swal2);

import {
  loadBaseList,
  createNewBase,
  updateExistingBase,
  deleteExistingBase,
  filterBaseList,
  getBaseHistory,
  putBaseHistory,
  saveFilter,
  filterBaseCollection,
  resetCurrentBase,
  setBaseFetching,
} from '../../actions/base_actions';
import {loadPartnerList} from '../../actions/partner_actions';

import {toastError, toastSuccess} from '../../actions/toast_actions';
import {Link} from 'react-router';
import {onFormFieldChange} from '../../actions/form_actions';
import TableWrapper, {TableButton} from '../common/TableWrapper';

import CustomModal, {
  ModalButton,
  doOpenModal,
  doCloseModal,
  onModalClose,
} from '../common/CustomModal';

import BaseForm from '../forms/BaseFormStateFull';
import swal from 'sweetalert';

const CONTEXT_BASE = 'CONTEXT_BASE';
const CONTEXT_BASE_HISTORY = 'CONTEXT_BASE_HISTORY';

import {colourStyles} from '../common/ReactTable';
import {reset} from 'redux-form';
import {doPostV2} from '../../api/base/base_api_v2_helper';
import {hasProfiles, hasReboqueMeProfile} from '../../utils/check_permission';
import {APP_PROFILES} from '../../reducers/constants';
import {BASE_URL_V2} from '../../config';
import {formatData} from '../../utils/translationUtils';

const loadData = (currentPage, pageSize, callback) => {
  this.props.loadBaseList();
  const {baseList} = this.props;
  setTimeout(() => {
    callback({
      data: baseList.slice((currentPage - 1) * pageSize, currentPage * pageSize),
      currentPage,
    });
  }, 500);
};

const statusList = [
  {value: 'BASEATIVA', label: 'Ativo', color: '#28a745'},
  {value: 'BASEINATIVA', label: 'Inativo', color: 'red'},
  {value: 'BASEPENDENTEATIVACAO', label: 'Aguardando Ativação', color: '#ffc107'},
];

const patinsList = [
  {value: 1, label: '1', color: '#28a745'},
  {value: 2, label: '2', color: '#ffc107'},
  {value: 3, label: '3', color: '#ffc107'},
  {value: 4, label: '4', color: '#ffc107'},
];

const asaDeltaList = [
  {value: 'sim', label: 'Sim', color: '#28a745'},
  {value: 'não', label: 'Não', color: '#ffc107'},
];

const platformLengthList = [
  {value: '4.5', label: '4.5', color: '#28a745'},
  {value: '5', label: '5', color: '#28a745'},
  {value: '5.5', label: '5.5', color: '#28a745'},
  {value: '6', label: '6', color: '#28a745'},
  {value: '6.5', label: '6.5', color: '#28a745'},
  {value: '7', label: '7', color: '#28a745'},
  {value: '7.5', label: '7.5', color: '#28a745'},
  {value: '8', label: '8', color: '#28a745'},
  {value: '8.5', label: '8.5', color: '#28a745'},
  {value: '9', label: '9', color: '#28a745'},
  {value: '9.5', label: '9.5', color: '#28a745'},
  {value: '10', label: '10', color: '#28a745'},
  {value: '10.5', label: '10.5', color: '#28a745'},
  {value: '11', label: '11', color: '#28a745'},
  {value: '11.5', label: '11.5', color: '#28a745'},
  {value: '12', label: '12', color: '#28a745'},
];

const vehicleTypeList = [
  {value: 'VEICULOPARCEIROMOTOCICLETA', label: 'Motocicleta', color: '#28a745'},
  {value: 'VEICULOPARCEIROLEVE', label: 'Plataforma Leve', color: '#28a745'},
  {value: 'VEICULOPARCEIROUTILITARIO', label: 'Plataforma Utilitária', color: '#28a745'},
  {value: 'VEICULOPARCEIROPESADO', label: 'Plataforma Pesada', color: '#28a745'},
  {value: 'VEICULOPARCEIROLANCA', label: 'Lança', color: '#28a745'},
  {value: 'VEICULOPARCEIROAUTOMOVEL', label: 'Automovel', color: '#28a745'},
];

const tipoBaseList = [
  {value: 'cpf', label: 'CPF', color: '#28a745'},
  {value: 'cnpj', label: 'CNPJ', color: '#ffc107'},
];

const BASE_SEARCH_FORM = 'BASE_SEARCH_FORM';

class BaseTableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: this.props.form.filtro || '',
      log: '',
      status: this.props.form.status || [statusList[0]],
      asaDelta: this.props.form.asaDelta || '',
      tamanhoPlataforma: this.props.form.tamanhoPlataforma || '',
      patins: this.props.form.patins || '',
      tipoVeiculo: this.props.form.tipoVeiculo || '',
      inadimplencia: this.props.form.inadimplencia || '',
      tipoBase: this.props.form.tipoBase || '',
      radius: 5,
      currentPage: 1,
      sizePerPage: 10,
    };
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleLog = this.handleLog.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.prePutLog = this.prePutLog.bind(this);
    this.onKeyDownFilter = this.onKeyDownFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.props.resetCurrentBase();
  }

  updateTableState({data, currentPage}) {
    this.setState({baseList: data, currentPage});
  }

  _onNext() {
    const {currentPage, pageSize} = this.state;
    loadData(currentPage + 1, pageSize, this.updateTableState);
  }

  handleRedirect(id) {
    browserHistory.push(`/bases/${id}/partners`);
  }

  showHistory = (rowData) => {
    this.props.handleBaseCode(rowData.codigo);
    this.props.showHistoryBaseModal(rowData);
  };

  deleteBase = (rowData) => {
    this.props.deleteBase(rowData);
  };
  showTruck = (rowData) => {
    this.props.resetCurrentBase();
    this.handleRedirect(rowData.codigo);
  };

  prePutLog() {
    this.props.putLog(this.state.currentBaseCode, this.state.log);
    this.setState({log: ''});
  }
  handleKeyUp(e) {
    const {putLog} = this.props;
    const {log} = this.state;
    if (e.key === 'Enter' && e.ctrlKey) {
      putLog(this.state.currentBaseCode, log);
      this.setState({log: ''});
    }
  }
  handleLog(e) {
    this.setState({log: e.target.value});
  }
  componentWillUnmount() {
    this.props.saveAndClearFilter();
  }

  fetchFilter = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.filterBases(this.state.filterText);
    }, 900);
  };

  clear = () => {
    this.props.filterBases('');
    this.setState({...this.state, filterText: ''});
  };

  formatMultipleFilter = (attribute) => {
    return this.state[attribute].map((item) => item.value).join(',');
  };
  handlePageChange = (page, sizePerPage) => {
    console.log(page, sizePerPage);
    this.setState({currentPage: page, sizePerPage}, () => {
      this.handleSearch();
    });
  };

  handleSearch = (event) => {
    const multiples = ['status', 'tamanhoPlataforma', 'patins', 'tipoVeiculo'];
    const attributes = multiples.reduce((acum, item) => {
      if (this.state[item] !== '') {
        const value = this.formatMultipleFilter(item);
        if (value) acum['_' + item] = value;
      }
      return acum;
    }, {});
    if (this.state.asaDelta) attributes._asaDelta = this.state.asaDelta.value;
    if (this.state.inadimplencia) attributes._inadimplente = this.state.inadimplencia.value;
    if (this.state.tipoBase) attributes._tipoDocumento = this.state.tipoBase.value;
    if (this.props.endereco.service.baseEndereco) {
      const {latitude, longitude} = this.props.endereco.service.baseEndereco;
      if ((latitude, longitude)) {
        if (this.state.radius) attributes._radius = this.state.radius;
        attributes._lat = this.props.endereco.service.baseEndereco.latitude;
        attributes._long = this.props.endereco.service.baseEndereco.longitude;
      }
    }

    if (event) {
      this.setState({ currentPage: 1})
    }
    const page = !event ? this.state.currentPage : 1;
    const limit = this.state.sizePerPage;
    this.props.filterBases(this.state.filterText, attributes, page, limit);
  };

  onChangeFilter = (e) => {
    this.setState({...this.state, filterText: e.target.value});
    this.props.onFieldChange(BASE_SEARCH_FORM, 'filtro', e.target.value);
  };

  onKeyDownFilter(event) {
    if (this.state.filterText && event.key === 'Enter') {
      this.handleSearch(event);
    }
  }

  handleAnnouncement() {
    MySwal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja enviar uma nova campanha para todos os prestadores ?',
      input: 'textarea',
      inputPlaceholder: 'Mensagem da campanha',
      icon: 'warning',
      width: '80',
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#25f4af',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      preConfirm: (item) => {
        if (!item) {
          MySwal.showValidationMessage(`Por favor preencha a mensagem da campanha`);
        } else {
          return axios
            .post(
              `${BASE_URL_V2}partners/campaign`,
              {message: item},
              {
                auth: {
                  username: localStorage.getItem('username'),
                  password: localStorage.getItem('password'),
                },
              },
            )
            .then(() => {
              toastSuccess('Campanha iniciada com sucesso!');
            })
            .catch(() => {
              toastError('Não foi possível enviar a campanha!');
            });
        }
      },
      inputAttributes: {
        style: 'font-size: 14px;',
      },
    });
  }

  showCampaign() {
    if (hasReboqueMeProfile()) {
      return hasProfiles([
        APP_PROFILES.Administrador,
        APP_PROFILES.FinanceiroSoon,
        APP_PROFILES.SupervisorRede,
      ]);
    }

    return false;
  }

  onChangeSelect = (field, value) => {
    this.setState({...this.state, [field]: value});
    this.props.onFieldChange(BASE_SEARCH_FORM, field, value);
  };
  render() {
    const {baseCollection} = this.props;
    const formattedBaseCollection = Array.isArray(baseCollection)
      ? baseCollection.map((item) => ({
          ...item,
          dataCadastro: formatData(item.dataCadastro),
        }))
      : [];

    function getMarkers(min, max, step) {
      const range = _.range(min, max + 1, step);
      const marks = Object.fromEntries(range.map((number) => [number, `${number}km`]));
      return marks;
    }

    return (
      <div className="row">
        <CustomModal title="Detalhes da Base" context={CONTEXT_BASE}>
          <BaseForm
            onSaveDispatch={createNewBase(CONTEXT_BASE, this.props.doAfterCreate)}
            onUpdateDispatch={updateExistingBase(CONTEXT_BASE, this.props.doAfterUpdate)}
            context={CONTEXT_BASE}
            clearForm={this.props.clearValues}
          />
        </CustomModal>
        <div className="col-md-12">
          <div className="wrapper wrapper-content">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Bases Cadastradas</h5>
                <div className="ibox-tools">
                  {this.showCampaign() && (
                    <button
                      type="button"
                      className="btn btn-primary btn-sm"
                      onClick={() => this.handleAnnouncement()}>
                      Campanha
                      <i className="fa fa-bullhorn" style={{marginLeft: '10px'}} />
                    </button>
                  )}
                  <a href={`${process.env.REACT_APP_URL}/v2/bases/create`} target="_top">
                    <button type="button" className="btn btn-primary btn-sm">
                      Nova Base
                      <i className="fa fa-plus" style={{marginLeft: '10px'}} />
                    </button>
                  </a>
                </div>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label>Filtro</label>
                      <input
                        onChange={this.onChangeFilter}
                        className="form-control"
                        onKeyDown={this.onKeyDownFilter}
                        value={this.state.filterText}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 float-right">
                    <label className="font-normal p-w-xs">Status</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={statusList}
                      BaseTableContainer
                      isMulti
                      onChange={(value) => this.onChangeSelect('status', value)}
                      value={this.state.status}
                      styles={colourStyles}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8">
                    <div className="form-group">
                      <label>Filtro Endereço</label>
                      <AddressAutoComplete
                        placeholder="Informe o endereço"
                        id="baseEndereco"
                      />
                    </div>
                  </div>
                  <div className="col-md-4 float-right">
                    <label className="font-normal p-w-xs">Raio</label>
                    <Slider
                      min={5}
                      max={45}
                      dots
                      step={5}
                      onChange={(e) => this.setState({radius: e})}
                      value={this.state.radius}
                      marks={getMarkers(5, 45, 10)}></Slider>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <label className="font-normal p-w-xs">Tipo</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={vehicleTypeList}
                      value={this.state.tipoVeiculo}
                      onChange={(value) => this.onChangeSelect('tipoVeiculo', value)}
                      isMulti
                      styles={selectCustomStyle}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="font-normal p-w-xs">Patins</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={patinsList}
                      isMulti
                      onChange={(value) => this.onChangeSelect('patins', value)}
                      value={this.state.patins}
                      styles={selectCustomStyle}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="font-normal p-w-xs">Tamanho plataforma</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={platformLengthList}
                      value={this.state.tamanhoPlataforma}
                      onChange={(value) => this.onChangeSelect('tamanhoPlataforma', value)}
                      isMulti
                      styles={selectCustomStyle}
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{marginTop: '10px', display: 'flex', alignItems: 'flex-end'}}>
                  <div className="col-md-4">
                    <label className="font-normal p-w-xs">Asa Delta</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={asaDeltaList}
                      onChange={(value) => this.onChangeSelect('asaDelta', value)}
                      value={this.state.asaDelta}
                      styles={{...colourStyles, ...selectCustomStyle}}
                      isClearable
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="font-normal p-w-xs">Inadimplência</label>
                    <Select
                      closeMenuOnSelect={false}
                      options={asaDeltaList}
                      onChange={(value) => this.onChangeSelect('inadimplencia', value)}
                      value={this.state.inadimplencia}
                      styles={{...colourStyles, ...selectCustomStyle}}
                      isClearable
                    />
                  </div>
                  <div className="col-md-4">
                    <div className="row" style={{display: 'flex', alignItems: 'flex-end'}}>
                      <div className="col-md-9">
                        <label className="font-normal p-w-xs">Tipo Base</label>
                        <Select
                          closeMenuOnSelect={false}
                          isClearable
                          options={tipoBaseList}
                          value={this.state.tipoBase}
                          onChange={(value) => this.onChangeSelect('tipoBase', value)}
                          styles={selectCustomStyle}
                        />
                      </div>
                      <div className="col-md-3">
                        <button className="btn btn-primary" onClick={this.handleSearch}>
                          <i className="fa fa-search" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BaseTable
                key={`page-${this.state.currentPage}`}
                baseList={formattedBaseCollection}
                showHistory={this.showHistory}
                page={this.state.currentPage}
                onPageChange={this.handlePageChange}
                showTruck={this.showTruck}
                deleteBase={this.deleteBase}
                isFetching={this.props.baseListFetching}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doAfterUpdate: () => {
      dispatch(loadBaseList());
      doCloseModal(CONTEXT_BASE);
      clearFormValues(CONTEXT_BASE);
      toastSuccess('Base atualizada com sucesso');
    },
    resetCurrentBase: () => {
      dispatch(resetCurrentBase());
      dispatch(setBaseFetching());
      dispatch(reset());
    },
    doAfterCreate: () => {
      dispatch(loadBaseList());
      doCloseModal(CONTEXT_BASE);
      clearFormValues(CONTEXT_BASE);
      toastSuccess('Base adicionada com sucesso');
    },
    clearValues: () => {
      dispatch(clearFormValues(CONTEXT_BASE));
    },
    loadBaseList: () => {
      dispatch(loadBaseList());
    },
    loadPartnerList: (id) => {
      dispatch(loadPartnerList(id));
    },
    onFieldChange: (context, id, value) => {
      dispatch(onFormFieldChange(context, id, value));
    },
    showNewBaseForm: () => {
      dispatch(setEditingMode(CONTEXT_BASE, false));
      dispatch(clearFormValues(CONTEXT_BASE));
      doOpenModal(CONTEXT_BASE);
    },
    showExistingBaseForm: (data) => {
      dispatch(setEditingMode(CONTEXT_BASE, true));
      dispatch(setFormValues(CONTEXT_BASE, data));
      doOpenModal(CONTEXT_BASE);
    },
    showHistoryBaseModal: (rowData) => {
      dispatch([getBaseHistory(rowData.codigo), doOpenModal(CONTEXT_BASE_HISTORY)]);
    },
    putLog: (codigo, log) => {
      dispatch(putBaseHistory(codigo, log));
    },
    deleteBase: (base) => {
      let doAfterDelete = () => toastSuccess('Base removida com sucesso');
      swal(
        {
          title: 'Deseja excluir a base?',
          showCancelButton: true,
          closeOnConfirm: true,
          animation: 'slide-from-top',
          type: 'warning',
        },
        () => {
          dispatch(deleteExistingBase(base, doAfterDelete));
        },
      );
    },
    showBaseCars: (baseId) => {
      dispatch(loadBaseVehicles(baseId));
      doOpenModal(CONTEXT_VEHICLES);
    },
    filterBases: (filter, attributes, page, limit) => {
      dispatch(filterBaseCollection(filter, attributes, page, limit));
    },
    saveAndClearFilter: () => {
      dispatch([saveFilter(), filterBaseList('')]);
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    baseCollection: state.base.baseCollection,
    baseFilter: state.base.baseFilter,
    logList: state.base.baseHistory,
    baseListFetching: state.base.baseListFetching,
    form: state.forms.BASE_SEARCH_FORM,
    endereco: state,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseTableContainer);
